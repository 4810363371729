import { Flex, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { FaWallet } from "react-icons/fa6";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export default function BridgeNotConnected({}) {
  return (
    <Flex alignItems="center" h="100%" maxW="400px">
      <Stack spacing={5}>
        <HStack>
          <Icon
            //   color="gray.rb"
            boxSize={6}
            as={FaWallet}
            alignSelf="center"
          />
          <Text fontSize="2xl" fontWeight="semibold" ml="1">
            No Wallet
          </Text>
        </HStack>
        <Text fontSize="md">
          Please connect your wallet by pressing the button below to begin using
          the bridge.
        </Text>

        <Flex mt="2" alignSelf="center">
          <ConnectButton label="Connect your wallet" />
        </Flex>
      </Stack>
    </Flex>
  );
}
