import { useEffect, useState } from "react";
import Parse from "parse";
import {
  Box,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
  Link,
  Flex,
  Spacer,
  VStack,
  Button,
} from "@chakra-ui/react";
import { useNetwork, useSwitchNetwork } from "wagmi";
import SlidingPane from "react-sliding-pane";
import Lottie from "lottie-react";
import {
  FaXmark,
  FaTimeline,
  FaPaperPlane,
  FaPlaneDeparture,
  FaDownload,
  FaPlaneArrival,
} from "react-icons/fa6";
// import Web3 from "web3";
import { formatEther } from "viem";
import { cwError, getTimeDifference } from "../js/helpers";
import { getEllipsisTxt } from "../js/formatters";
import LoadingAnimation from "../assets/loadingDotsLottieColor.json";
import CountdownTimer from "../hooks/useCountdownTimer";
import useCountdownTimer from "../hooks/useCountdownTimer";

// const web3 = new Web3(Web3.givenProvider);

export default function SlidingPaneClaim({
  slidingPaneClaimState,
  setSlidingPaneClaimState,
  account,
  debouncedAccount,
  chainsWithUrl,
  claimTokens,
  isSubmitting,
  bridgingCooldown,
}) {
  const [accountToClaim, setAccountToClaim] = useState();
  const { chain } = useNetwork();
  const {
    switchNetworkAsync,
    isLoading: switchingNetwork,
    reset: switchingNetworkReset,
  } = useSwitchNetwork();

  const sliderWidth = useBreakpointValue(
    {
      base: "100vw",
      md: "80vw",
      lg: "70vw",
      xl: "60vw",
    },
    {
      // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
      // (Defaults to 'base')
      fallback: "md",
    }
  );

  const formatResults = (results) => {
    // console.log("results: " + JSON.stringify(results));
    if (Array.isArray(results)) {
      let array1 = results.filter((x) => x.get("code") != "2");
      let array2 = results.filter((x) => x.get("code") == "2");
      let arrayCombined = [];
      for (let i = 0; i < array1.length; i++) {
        const claimed = array2.find(
          (x) => x.get("blockTime") == array1[i].get("blockTime")
        );
        if (!claimed) {
          arrayCombined.push(array1[i]);
          // console.log("!claimed id: " + array1[i].id);
        } else {
          const notConfirmed = array2.find(
            (x) =>
              x.get("confirmed") == false &&
              x.get("blockTime") == array1[i].get("blockTime")
          );
          if (notConfirmed) {
            arrayCombined.push(notConfirmed);
            // console.log("notConfirmed id: " + notConfirmed.id);
          }
        }
      }
      const reversedArray = arrayCombined.reverse();
      setAccountToClaim(reversedArray);
    }
  };

  const getAccountToClaim = async () => {
    try {
      // console.log("chainsWithUrl: " + process.env.REACT_APP_SERVER_URL);
      const queryAccount = new Parse.Query("TokenbridgeLogs");
      queryAccount.equalTo("to", account.address.toLowerCase());
      queryAccount.equalTo("name", "ClaimTokens");
      // queryAccount.containedIn("code", ["1", "2"]);
      queryAccount.containedIn(
        "toChain",
        chainsWithUrl.map((x) => String(x.id))
      );
      queryAccount.containedIn(
        "fromChain",
        chainsWithUrl.map((x) => String(x.id))
      );
      queryAccount.descending("blockTimestamp");
      const results = await queryAccount.find();
      formatResults(results);
    } catch (error) {
      cwError(error, "getAccountToClaim");
    }
  };

  const subAccountToClaim = async () => {
    try {
      // console.log("chainsWithUrl: " + process.env.REACT_APP_SERVER_URL);
      const queryAccount = new Parse.Query("TokenbridgeLogs");
      queryAccount.equalTo("to", account.address.toLowerCase());
      queryAccount.equalTo("name", "ClaimTokens");
      // queryAccount.containedIn("code", ["1", "2"]);
      queryAccount.containedIn(
        "toChain",
        chainsWithUrl.map((x) => String(x.id))
      );
      queryAccount.containedIn(
        "fromChain",
        chainsWithUrl.map((x) => String(x.id))
      );
      queryAccount.descending("blockTimestamp");
      const subscription = await queryAccount.subscribe();
      subscription.on("create", getAccountToClaim);
      subscription.on("update", getAccountToClaim);
      subscription.on("enter", getAccountToClaim);
      return subscription;
    } catch (error) {
      cwError(error, "subAccountToClaim");
    }
  };

  useEffect(() => {
    getAccountToClaim();
    let subscription;

    (async () => {
      subscription = await subAccountToClaim();
      console.log("subAccountToClaim: " + JSON.stringify(subscription));
      return () => {
        try {
          if (subscription) {
            subscription.unsubscribe();
            console.log("***UNsubbed subAccountToClaim");
          }
        } catch (error) {
          cwError(error, "useEffect subAccountToClaim");
        }
      };
    })();

    return () => {
      try {
        if (subscription) {
          subscription.unsubscribe();
          console.log("***UNsubbed subAccountHistory");
        }
      } catch (error) {
        cwError(error, "useEffect subAccountHistory");
      }
    };
  }, [debouncedAccount]);

  const handleClaim = async (claimObject) => {
    // console.log("claiming: " + claimObject.id);
    try {
      if (!isSubmitting) {
        // console.log("chain: " + JSON.stringify(chain));
        // console.log("String(chain.id): " + String(chain.id));
        // console.log("claimObject.get(toChain): " + claimObject.get("toChain"));
        if (String(chain.id) != claimObject.get("toChain")) {
          // console.log("switch network!");
          switchNetworkAsync?.(claimObject.get("toChain"));
          setTimeout(() => switchingNetworkReset(), 5000);
        } else {
          await claimTokens(claimObject.id);
        }
      }
    } catch (error) {
      cwError(error, "handleClaim");
    }
  };

  return (
    <SlidingPane
      className="slider-claim"
      overlayClassName="slider-claim-overlay"
      isOpen={slidingPaneClaimState}
      title={
        <HStack spacing="4">
          <Icon
            // className="button-animation"
            minHeight="36px"
            minWidth="36px"
            as={FaTimeline}
          />
          <Text as="h2" fontWeight="semibold">
            Bridges to claim
          </Text>
        </HStack>
      }
      subtitle={
        <Text fontSize="14px" fontWeight="medium">
          {account.address}
        </Text>
      }
      width={sliderWidth}
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        setSlidingPaneClaimState(false);
      }}
      closeIcon={
        <Icon
          className="button-animation"
          minHeight="30px"
          minWidth="30px"
          as={FaXmark}
        />
      }
    >
      {accountToClaim && accountToClaim.length <= 0 && (
        <Text as="h5" py="10" px={["10"]}>
          No bridges to claim for this address
        </Text>
      )}
      {accountToClaim &&
        accountToClaim.length > 0 &&
        accountToClaim.map((x, i) => (
          <div key={x.id + i}>
            <ItemToClaim
              chainsWithUrl={chainsWithUrl}
              item={x}
              index={i}
              handleClaim={handleClaim}
              chain={chain}
              isSubmitting={isSubmitting}
              switchingNetwork={switchingNetwork}
              bridgingCooldown={bridgingCooldown}
            />
          </div>
        ))}
    </SlidingPane>
  );
}

const ItemToClaim = ({
  chainsWithUrl,
  item,
  index,
  handleClaim,
  chain,
  isSubmitting,
  switchingNetwork,
  bridgingCooldown,
}) => {
  const { minutesRemaining, timesUp, secondsRemaining, showSeconds } =
    useCountdownTimer({
      targetDate: item.get("blockTime") * 1000 + bridgingCooldown * 60000,
    });

  return (
    <Flex
      key={item.id}
      py="5"
      px={["5", "7", "10"]}
      backgroundColor={item.get("code") != "2" ? "white" : "blackAlpha.50"}
      borderBottom="solid 1px #eeeeee"
    >
      <HStack
        as={!item.get("confirmed") || !timesUp ? "a" : "div"}
        onClick={
          !item.get("confirmed") || !timesUp
            ? () => {}
            : () => handleClaim(item)
        }
        target="_blank"
        href={`${
          chainsWithUrl?.find(
            (y) =>
              y.id ==
              item.get(
                item.get("name") == "ClaimTokens" ? "toChain" : "fromChain"
              )
          )?.blockExplorers?.default.url
        }/tx/${item.get("transactionHash")}`}
        className="button2-animation"
        cursor="pointer"
        spacing={["5", "7", "10"]}
        flex="1"
        // backgroundColor={
        //   x.get("name") == "ClaimTokens" ? "white" : "blackAlpha.50"
        // }
      >
        <Icon
          // className="button-animation"
          minHeight="24px"
          minWidth="24px"
          as={
            item.get("code") == "2"
              ? FaPlaneDeparture
              : item.get("confirmed") == true
              ? FaDownload
              : FaPlaneArrival
          }
        />
        <VStack alignItems="flex-start">
          <Text fontSize="sm">
            {new Date(item.get("blockTimestamp") * 1000).toString()}
          </Text>
          <HStack>
            <Text as="h5">Claim</Text>
            <Text as="h5" fontWeight="bold">
              {/* {` ${web3.utils.fromWei(item.get("amount"))} YOU `} */}
              {` ${formatEther(item.get("amount"))} YOU `}
            </Text>
            <Text as="h5">tokens</Text>
          </HStack>
          <Text>
            Sent from{" "}
            {chainsWithUrl?.find((y) => y.id == item.get("fromChain")).name} to{" "}
            {chainsWithUrl?.find((y) => y.id == item.get("toChain")).name}
          </Text>
          <HStack alignItems="flex-end">
            <Text fontSize="sm">Tx hash: </Text>

            <Text fontSize="sm" fontWeight="semibold" as="u">
              {getEllipsisTxt(item.get("transactionHash"), 13)}
            </Text>
          </HStack>
          <Flex display={["flex", "none"]} alignSelf="flex-end">
            {!item.get("confirmed") ? (
              <Flex minH="60px" w="60px" alignSelf="center">
                <Lottie animationData={LoadingAnimation} loop={true} />
              </Flex>
            ) : (
              <>
                {timesUp ? (
                  <Flex minH="60px" alignItems="center">
                    {String(chain.id) != item.get("toChain") ? (
                      <Button
                        onClick={() => {}}
                        colorScheme="white"
                        backgroundColor="secondary"
                        isDisabled={Boolean(isSubmitting || switchingNetwork)}
                        isLoading={Boolean(isSubmitting || switchingNetwork)}
                      >
                        Switch network
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {}}
                        colorScheme="white"
                        backgroundColor="primary"
                        isDisabled={Boolean(isSubmitting || switchingNetwork)}
                        isLoading={Boolean(isSubmitting || switchingNetwork)}
                      >
                        Claim now
                      </Button>
                    )}
                  </Flex>
                ) : (
                  <HStack alignItems="flex-end" mt="3">
                    <Text textAlign="right">Claim in</Text>
                    <Text
                      as="h4"
                      color="primary"
                      fontWeight="black"
                      textAlign="right"
                    >{` ${
                      showSeconds ? secondsRemaining : minutesRemaining
                    }`}</Text>
                    <Text textAlign="right">{` ${
                      showSeconds ? " seconds" : " minutes"
                    }`}</Text>
                  </HStack>
                )}
              </>
            )}
          </Flex>
        </VStack>
        <Spacer display={["none", "flex"]} />
        <Flex display={["none", "flex"]}>
          {!item.get("confirmed") ? (
            <Flex minH="60px" w="60px" alignSelf="center">
              <Lottie animationData={LoadingAnimation} loop={true} />
            </Flex>
          ) : (
            <>
              {timesUp ? (
                <Flex minH="60px" alignItems="center">
                  {String(chain.id) != item.get("toChain") ? (
                    <Button
                      onClick={() => {}}
                      colorScheme="white"
                      backgroundColor="secondary"
                      isDisabled={Boolean(isSubmitting || switchingNetwork)}
                      isLoading={Boolean(isSubmitting || switchingNetwork)}
                    >
                      Switch network
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {}}
                      colorScheme="white"
                      backgroundColor="primary"
                      isDisabled={Boolean(isSubmitting || switchingNetwork)}
                      isLoading={Boolean(isSubmitting || switchingNetwork)}
                    >
                      Claim now
                    </Button>
                  )}
                </Flex>
              ) : (
                <VStack alignItems="flex-end">
                  <Text textAlign="right">Claim in</Text>
                  <Text
                    as="h1"
                    color="primary"
                    fontWeight="black"
                    textAlign="right"
                  >{` ${
                    showSeconds ? secondsRemaining : minutesRemaining
                  }`}</Text>
                  <Text textAlign="right">{` ${
                    showSeconds ? " seconds" : " minutes"
                  }`}</Text>
                </VStack>
              )}
            </>
          )}
        </Flex>
      </HStack>
    </Flex>
  );
};
