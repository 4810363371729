export const clError = (error, fnName = "clError") => {
  console.log(fnName + " error: " + error.name + " " + error.message);
};

export const cwError = (error, fnName = "cwError") => {
  console.warn(fnName + " error: " + error.name + " " + error.message);
};

export const ceError = (error, fnName = "ceError") => {
  console.error(fnName + " error: " + error.name + " " + error.message);
};

export const getTimeDifference = (dateA, dateB) => {
  return Math.floor((dateA.getTime() - dateB.getTime()) / 1000);
};
