import { useFormikContext } from "formik";
import { Button, Text } from "@chakra-ui/react";

export default function AppFormSubmitButton({
  isLoading,
  isDisabled,
  buttonText = "Submit",
  buttonTextComponent,
  fontSize = "2xl",
  ...otherProps
}) {
  const { handleSubmit, isSubmitting, isValid } = useFormikContext();
  return (
    <Button
      className={isDisabled ? "" : isLoading ? "" : "button-animation"}
      isLoading={isLoading ? isLoading : isSubmitting}
      isDisabled={isDisabled ? isDisabled : isLoading ? isLoading : !isValid}
      onClick={handleSubmit}
      {...otherProps}
    >
      <>
        {buttonTextComponent ? (
          buttonTextComponent
        ) : (
          <Text fontSize={fontSize}>{buttonText}</Text>
        )}
      </>
    </Button>
  );
}
