// theme.js
import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
  colors: {
    transparent: "transparent",
    black: "#25292E",
    white: "#fff",
    gray: {
      rb: "rgba(60, 66, 66, 0.8)",
      50: "#f7fafc",
      // ...
      900: "#171923",
    },
    primary: "#00c999",
    secondary: "#ff5e0e",
    // ...
  },
});
