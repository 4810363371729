import { useFormikContext } from "formik";

import {
  HStack,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Flex,
  Text,
} from "@chakra-ui/react";
import AppFormErrorMessage from "./AppFormErrorMessage";
import { useEffect } from "react";

export default function AppFormNumberInput({
  balance,
  name,
  noErrorMessage,
  onChangeField,
  ...otherProps
}) {
  const { setFieldTouched, setFieldValue, errors, touched, values } =
    useFormikContext();

  const handleChangeTextField = (text) => {
    setFieldValue(name, text);
    // setFieldTouched(name);
    setTimeout(() => setFieldTouched(name, true)); // workaround to make valid check keep up with latest render when clicking max button
    if (onChangeField) onChangeField(text);
  };

  useEffect(() => {
    setFieldValue(name, balance);
  }, [balance]);

  return (
    <>
      <FormControl>
        <Flex justifyContent="flex-end" mb="1" mr="1">
          <Text>
            Max:{" "}
            <Text
              as="button"
              fontWeight="bold"
              onClick={() => handleChangeTextField(balance)}
              textDecoration="underline"
            >
              {balance} YOU
            </Text>
          </Text>
        </Flex>
        <HStack spacing={3} alignItems="center">
          <Image
            //   borderRadius="full"
            boxSize="60px"
            src="https://why.youwho.io/media/img/youwho-teal_logo-for-cmc-200x200.png"
            alt="YOU"
          />

          <NumberInput
            flex="1"
            defaultValue={values[name] ?? balance}
            max={balance}
            min={0}
            focusBorderColor="primary"
            size="lg"
            value={values[name]}
            // precision="18"
            // onFocus={setFieldTouched(name)}
            clampValueOnBlur={false}
            onChange={(text) => handleChangeTextField(text)}
            {...otherProps}
          >
            <NumberInputField
              borderRadius="12px"
              fontSize="24px"
              fontWeight="semibold"
              //   onBlur={setFieldTouched(name)}
              _focus={{ backgroundColor: "gray.50" }}
              // bg="gray.50"
              py="8"
              px="4"
              pr="8"
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </HStack>
        {/* {Boolean(values[name] <= 0) &&
          Boolean(touched[name]) &&
          !Boolean(errors[name]) && (
            <FormHelperText
              fontSize="sm"
              as="u"
              fontWeight="bold"
              display="flex"
            >
              Enter the amount of YOU tokens you would like to bridge.
            </FormHelperText>
          )}

        {!noErrorMessage && Boolean(errors[name]) && (
          <FormErrorMessage
            fontSize="sm"
            as="u"
            fontWeight="bold"
            display="flex"
            color="danger"
          >
            {errors[name]}
          </FormErrorMessage>
        )} */}
        <AppFormErrorMessage name={name} />
      </FormControl>
    </>
  );
}
