import { useEffect, useState } from "react";
import Parse from "parse";
// import Web3 from "web3";

import {
  useAccount,
  useWalletClient,
  useWaitForTransaction,
  erc20ABI,
  usePublicClient,
} from "wagmi";
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit";

import { getContract } from "wagmi/actions";
import { useToast } from "@chakra-ui/react";
import { cwError } from "../js/helpers";
import { getErrorDetails } from "../js/formatters";

// const web3Given = new Web3(Web3.givenProvider);

// const web3Obj = {
//   // 1: web3eth,
//   5: web3Given,
//   // 56: web3bsc,
//   97: web3Given,
//   // 42161: web3arb,
//   421613: web3Given,
// };

export default function useSolidity(fromChainId, toChainId, setIsSubmitting) {
  const [configObject, setConfigObject] = useState();
  const [tokenAllowance, setTokenAllowance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [bridgeAbi, setBridgeAbi] = useState();
  const [bridgeIsPaused, setBridgeIsPaused] = useState();
  const [bridgeInfoFrom, setBridgeInfoFrom] = useState();
  const [bridgeInfoTo, setBridgeInfoTo] = useState();
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const { address } = useAccount();
  const toast = useToast();
  const addRecentTransaction = useAddRecentTransaction();

  const getBridgeInfo = async () => {
    const query = new Parse.Query("Config");
    const configObject1 = await query.first();

    if (!configObject1) throw new Error("Config not found");
    setConfigObject(configObject1);
    setBridgeAbi(configObject1.get("bridgeAbi"));
    setBridgeInfoFrom(configObject1.get("bridgeInfo")[String(fromChainId)]);
    setBridgeInfoTo(configObject1.get("bridgeInfo")[String(toChainId)]);
    setBridgeIsPaused(configObject1.get("bridgeIsPaused"));
  };

  useEffect(() => {
    getBridgeInfo();
  }, [fromChainId, toChainId]);

  const getTokenBalance = async () => {
    try {
      const tokenAddress = bridgeInfoFrom.youTokenAddress;
      const tokenContract = getContract({
        address: tokenAddress,
        abi: erc20ABI,
        walletClient,
      });

      const tokenBalance1 = await tokenContract.read.balanceOf([address]);
      setTokenBalance(tokenBalance1);

      return tokenBalance1;
    } catch (error) {
      setTokenBalance(0);
      cwError(error, "getTokenBalance");
      if (!toast.isActive(error.name)) {
        toast({
          id: error.name,
          title: "Error getting token balance",
          description: getErrorDetails(error.message),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const getTokenAllowance = async () => {
    try {
      // console.log("fromChainId: " + JSON.stringify(fromChainId));
      // console.log("toChainId: " + JSON.stringify(toChainId));
      const bridgeAddress = bridgeInfoFrom.address;
      const tokenAddress = bridgeInfoFrom.youTokenAddress;
      const tokenContract = getContract({
        address: tokenAddress,
        abi: erc20ABI,
        walletClient,
      });
      const tokenAllowance1 = await tokenContract.read.allowance([
        address,
        bridgeAddress,
      ]);
      setTokenAllowance(tokenAllowance1);

      return tokenAllowance1;
    } catch (error) {
      cwError(error, "getTokenAllowance");
    }
  };

  const approveTokenAllowance = async (amount) => {
    try {
      const bridgeAddress = bridgeInfoFrom.address;
      const tokenAddress = bridgeInfoFrom.youTokenAddress;
      const tokenContract = getContract({
        address: tokenAddress,
        abi: [
          ...erc20ABI,
          {
            inputs: [],
            name: "_cap",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
        ],
        walletClient,
      });
      const tokenCap = await tokenContract.read._cap();
      // console.log("tokenCap: " + tokenCap);
      const results = await tokenContract.write.approve([
        bridgeAddress,
        tokenCap,
      ]);
      //   console.log("results: " + JSON.stringify(results));
    } catch (error) {
      cwError(error, "approveTokenAllowance");
      if (!toast.isActive(error.name)) {
        toast({
          id: error.name,
          title: "Error approving token allowance",
          description: getErrorDetails(error.message),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (bridgeInfoFrom && address) {
      const unwatchTokenApproval = publicClient.watchContractEvent({
        address: bridgeInfoFrom.youTokenAddress,
        abi: erc20ABI,
        eventName: "Approval",
        args: { owner: address, spender: bridgeInfoFrom.address },
        onLogs: (logs) => {
          console.log(
            "new bridge token allowance: " + logs[0]?.args?.value?.toString()
          );
          getTokenAllowance();
          setIsSubmitting(false);
        },
      });

      console.log("watching Token Approval");

      return () => {
        unwatchTokenApproval();
        console.log("unwatched Token Approval");
      };
    }
  }, [bridgeInfoFrom, address]);

  const bridgeTokens = async (amount) => {
    try {
      //   await getBridgeInfo();

      //   console.log("tokenAllowance: " + tokenAllowance);
      //   console.log("amount: " + amount);
      //   console.log("bridgeInfoFrom: " + JSON.stringify(bridgeInfoFrom));
      //   console.log("bridgeInfoTo: " + JSON.stringify(bridgeInfoTo));
      //   console.log("bridgeAbi: " + JSON.stringify(bridgeAbi));

      if (amount > tokenAllowance) {
        await approveTokenAllowance(amount);
        // await getTokenAllowance();
      } else {
        if (bridgeIsPaused)
          throw new Error("Bridge is paused. Please try again later.");

        const bridgeContract = getContract({
          address: bridgeInfoFrom.address,
          abi: bridgeAbi,
          walletClient,
        });

        const gasTx = await bridgeContract.estimateGas.bridgeTokens(
          [
            bridgeInfoFrom.youTokenAddress,
            bridgeInfoTo.youTokenAddress,
            toChainId,
            amount,
          ],
          { from: address }
        );

        // console.log("gasTx: " + gasTx);

        const txHash = await bridgeContract.write.bridgeTokens(
          [
            bridgeInfoFrom.youTokenAddress,
            bridgeInfoTo.youTokenAddress,
            toChainId,
            amount,
          ],
          {
            from: address,
            gas: String(Math.floor(gasTx.toString() * bridgeInfoFrom.feeX)), // increase the gas amount just to make sure the tx goes through. unused gas is returned.
          }
        );

        console.log("bridgeTokens txHash: " + txHash);
        addRecentTransaction({
          hash: txHash,
          description: "Sent tokens to bridge",
        });

        setTimeout(() => setIsSubmitting(false), 40000);
      }
    } catch (error) {
      cwError(error, "bridgeTokens");
      if (!toast.isActive(error.name)) {
        toast({
          id: error.name,
          title: "Error bridging tokens",
          description: getErrorDetails(error.message),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (
      bridgeInfoFrom &&
      // bridgeInfoTo &&
      // fromChainId &&
      // toChainId &&
      bridgeAbi &&
      address
    ) {
      const unwatchBridgeTokens = publicClient.watchContractEvent({
        address: bridgeInfoFrom.address,
        // address: "0xb311d402c66502B331E2C98450403F64694b09E2",
        abi: bridgeAbi,
        // abi: bridgeAbiJson,
        eventName: "BridgeTokens",
        args: {
          to: address,
          // tokenAddressFrom: bridgeInfoFrom.youTokenAddress,
          // fromChain: fromChainId,
          // tokenAddressTo: bridgeInfoTo.youTokenAddress,
          // toChain: toChainId,
        },
        onLogs: (logs) => {
          console.log(
            "new bridge submitted: " + JSON.stringify(logs ?? "no logs")
          );
          setIsSubmitting(false);
        },
      });

      console.log("watching BridgeTokens");

      return () => {
        unwatchBridgeTokens();
        console.log("unwatched BridgeTokens");
      };
    }
  }, [
    bridgeInfoFrom,
    // bridgeInfoTo,
    // fromChainId,
    // toChainId,
    bridgeAbi,
    address,
  ]);

  // bridgeTokens(NETWORK_ID_FROM, NETWORK_ID_TO, ACCOUNT, PK, AMOUNT);

  const claimTokens = async (objectId) => {
    try {
      setIsSubmitting(true);
      const queryLogs = new Parse.Query("TokenbridgeLogs");
      queryLogs.equalTo("objectId", objectId);
      queryLogs.equalTo("name", "ClaimTokens");
      queryLogs.equalTo("code", "1");
      const txLog = await queryLogs.first();

      if (!txLog)
        throw new Error(
          "Transaction doesn't exist or has not been logged yet."
        );
      if (!txLog.get("confirmed"))
        throw new Error(
          "Transaction has not been confirmed on the blockchain."
        );

      const networkIdFrom = txLog.get("fromChain");
      const networkIdTo = txLog.get("toChain");
      const addressTo = txLog.get("to");
      const amount = txLog.get("amount");
      const blockTime = txLog.get("blockTime");
      const tokenAddressFrom = txLog.get("tokenAddressFrom");
      const tokenAddressTo = txLog.get("tokenAddressTo");
      const chainIdTo = txLog.get("chainId");
      const bridgeAddressTo = txLog.get("address");
      const bridgingRelayFee = txLog.get("bridgingRelayFee");

      if (!(String(chainIdTo) == networkIdTo))
        throw new Error("Chain ID mismatch.");

      if (bridgeAddressTo.toLowerCase() != bridgeInfoFrom.address.toLowerCase())
        throw new Error(
          "To bridge address mismatch. Please change your wallet to the following address: " +
            bridgeAddressTo
        );

      if (bridgeIsPaused)
        throw new Error("Bridge is paused. Please try again later.");

      //   const web3Live = web3Obj[String(networkIdTo)];

      //   const bridgeContract = new web3Live.eth.Contract(
      //     bridgeAbi,
      //     bridgeInfoTo.address
      //   );

      ///////////////

      const claimContract = getContract({
        address: bridgeInfoFrom.address,
        abi: bridgeAbi,
        walletClient,
      });

      const gasTx = await claimContract.estimateGas.claimTokens(
        [
          tokenAddressFrom,
          networkIdFrom,
          tokenAddressTo,
          blockTime,
          bridgingRelayFee,
          amount,
        ],
        { from: addressTo, value: bridgingRelayFee }
      );

      // console.log("gasTx: " + gasTx);

      const txHash = await claimContract.write.claimTokens(
        [
          tokenAddressFrom,
          networkIdFrom,
          tokenAddressTo,
          blockTime,
          bridgingRelayFee,
          amount,
        ],
        {
          from: addressTo,
          value: bridgingRelayFee,
          gas: String(Math.floor(gasTx.toString() * bridgeInfoTo.feeX)), // increase the gas amount just to make sure the tx goes through. unused gas is returned.
        }
      );

      console.log("claimTokens txHash: " + txHash);
      addRecentTransaction({
        hash: txHash,
        description: "Claim bridged tokens",
      });

      //////////////////

      //   const gasTx = await bridgeContract.methods
      //     .claimTokens(
      //       tokenAddressFrom,
      //       networkIdFrom,
      //       tokenAddressTo,
      //       blockTime,
      //       bridgingRelayFee,
      //       amount
      //     )
      //     .estimateGas({ from: addressTo, value: bridgingRelayFee });

      //   const bridgingRelayGas = String(Math.floor(gasTx * bridgeInfoTo.feeX));

      //   const data = bridgeContract.methods
      //     .claimTokens(
      //       tokenAddressFrom,
      //       networkIdFrom,
      //       tokenAddressTo,
      //       blockTime,
      //       bridgingRelayFee,
      //       amount
      //     )
      //     .encodeABI();

      //   const transactionBody = {
      //     to: bridgeInfoTo.address,
      //     value: bridgingRelayFee,
      //     data,
      //     gas: bridgingRelayGas, // increase the gas cost just to make sure the tx goes through. unused gas is returned.
      //   };

      //   const signedTransaction = await web3Live.eth.accounts.signTransaction(
      //     transactionBody,
      //     privateKey
      //   );

      //   // console.log("signedTransaction: " + JSON.stringify(signedTransaction));

      //   const result = await web3Live.eth.sendSignedTransaction(
      //     signedTransaction.rawTransaction
      //   );

      //   console.log("result: " + JSON.stringify(result));

      // console.log("gasPrice: " + gasPrice);
      // console.log("gasTx: " + gasTx);
      // console.log("gasFee: " + gasFee);
      setTimeout(() => setIsSubmitting(false), 40000);
    } catch (error) {
      cwError(error, "claimTokens");
      if (!toast.isActive(error.name)) {
        toast({
          id: error.name,
          title: "Error claiming tokens",
          description: getErrorDetails(error.message),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (
      bridgeInfoFrom &&
      // bridgeInfoTo &&
      // fromChainId &&
      // toChainId &&
      bridgeAbi &&
      address
    ) {
      const unwatchClaimTokens = publicClient.watchContractEvent({
        address: bridgeInfoFrom.address,
        abi: bridgeAbi,
        eventName: "ClaimTokens",
        args: {
          to: address,
          // tokenAddressFrom: bridgeInfoFrom.youTokenAddress,
          // fromChain: fromChainId,
          // tokenAddressTo: bridgeInfoFrom.youTokenAddress,
          // toChain: fromChainId,
        },
        onLogs: (logs) => {
          console.log(
            "new claim submitted: " + JSON.stringify(logs ?? "no logs")
          );
          setIsSubmitting(false);
        },
      });

      console.log("watching ClaimTokens");

      return () => {
        unwatchClaimTokens();
        console.log("unwatched ClaimTokens");
      };
    }
  }, [
    bridgeInfoFrom,
    // bridgeInfoTo,
    // fromChainId,
    // toChainId,
    address,
    bridgeAbi,
  ]);

  return {
    bridgeTokens,
    claimTokens,
    getTokenBalance,
    getTokenAllowance,
    approveTokenAllowance,
    getBridgeInfo,
    configObject,
    tokenAllowance,
    tokenBalance,
    bridgeAbi,
    bridgeIsPaused,
    bridgeInfoFrom,
    bridgeInfoTo,
  };
}
