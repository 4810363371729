import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import BridgeConnected from "./BridgeConnected";
import BridgeNotConnected from "./BridgeNotConnected";
import BridgeNoChain from "./BridgeNoChain";

export default function AppAuth({}) {
  return (
    <Flex
      className="button-shadow"
      bg="white"
      // alignItems="center"
      justifyContent="center"
      minW={["100vw", "400px"]}
      maxW={["100vw"]}
      minH="300px"
      overflow="hidden"
      borderRadius={["0", "30px"]}
      px={["4", "7"]}
      py={["7", "7"]}
      top={["53%", , , "50%"]}
      left={["0", "50%"]}
      transform={["translate(0, -50%)", "translate(-50%, -50%)"]}
      pos="absolute"
      flex="1"
    >
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== "loading";
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === "authenticated");

          return (
            <div
              {...(!ready && {
                "aria-hidden": true,
                style: {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return <BridgeNotConnected />;
                }

                if (chain.unsupported) {
                  return <BridgeNoChain openChainModal={openChainModal} />;
                }

                return (
                  <>
                    <BridgeConnected
                      openChainModal={openChainModal}
                      account={account}
                      chain={chain}
                      openAccountModal={openAccountModal}
                    />
                  </>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    </Flex>
  );
}
