import { useEffect, useState } from "react";
import Parse from "parse";
import {
  Box,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
  Link,
  Flex,
  VStack,
  Spacer,
} from "@chakra-ui/react";
import SlidingPane from "react-sliding-pane";
import Lottie from "lottie-react";
import {
  FaXmark,
  FaBridgeWater,
  FaDownload,
  FaClockRotateLeft,
  FaCircleCheck,
  FaHandHoldingDollar,
  FaHandHoldingHeart,
} from "react-icons/fa6";
import { formatEther } from "viem";
// import Web3 from "web3";
import { cwError } from "../js/helpers";
import { getEllipsisTxt } from "../js/formatters";
import LoadingAnimation from "../assets/loadingDotsLottieColor.json";

// const web3 = new Web3(Web3.givenProvider);

export default function SlidingPaneHistory({
  slidingPaneHistoryState,
  setSlidingPaneHistoryState,
  account,
  debouncedAccount,
  chainsWithUrl,
}) {
  const [accountHistory, setAccountHistory] = useState();

  const sliderWidth = useBreakpointValue(
    {
      base: "100vw",
      md: "80vw",
      lg: "65vw",
      xl: "55vw",
    },
    {
      // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
      // (Defaults to 'base')
      fallback: "md",
    }
  );

  const formatResults = (results) => {
    if (Array.isArray(results)) {
      setAccountHistory(results);
    }
  };

  const getAccountHistory = async () => {
    try {
      // console.log("chainsWithUrl: " + process.env.REACT_APP_SERVER_URL);
      const queryAccount = new Parse.Query("TokenbridgeLogs");
      queryAccount.equalTo("to", account.address.toLowerCase());
      queryAccount.containedIn("name", ["ClaimTokens", "BridgeTokens"]);
      queryAccount.containedIn(
        "toChain",
        chainsWithUrl.map((x) => String(x.id))
      );
      queryAccount.containedIn(
        "fromChain",
        chainsWithUrl.map((x) => String(x.id))
      );
      queryAccount.descending("blockTimestamp");
      const results = await queryAccount.find();
      formatResults(results);
    } catch (error) {
      cwError(error, "getAccountHistory");
    }
  };

  const subAccountHistory = async () => {
    try {
      // console.log("chainsWithUrl: " + process.env.REACT_APP_SERVER_URL);
      const queryAccount = new Parse.Query("TokenbridgeLogs");
      queryAccount.equalTo("to", account.address.toLowerCase());
      queryAccount.containedIn(
        "toChain",
        chainsWithUrl.map((x) => String(x.id))
      );
      queryAccount.containedIn(
        "fromChain",
        chainsWithUrl.map((x) => String(x.id))
      );
      queryAccount.descending("blockTimestamp");
      const subscription = await queryAccount.subscribe();
      subscription.on("create", getAccountHistory);
      subscription.on("update", getAccountHistory);
      subscription.on("enter", getAccountHistory);
      return subscription;
    } catch (error) {
      cwError(error, "subAccountHistory");
    }
  };

  useEffect(() => {
    getAccountHistory();
    let subscription;

    (async () => {
      subscription = await subAccountHistory();
      console.log("subAccountHistory: " + JSON.stringify(subscription));
      return () => {
        try {
          if (subscription) {
            subscription.unsubscribe();
            console.log("***UNsubbed subAccountHistory");
          }
        } catch (error) {
          cwError(error, "useEffect subAccountHistory");
        }
      };
    })();

    return () => {
      try {
        if (subscription) {
          subscription.unsubscribe();
          console.log("***UNsubbed subAccountHistory");
        }
      } catch (error) {
        cwError(error, "useEffect subAccountHistory");
      }
    };
  }, [debouncedAccount]);

  return (
    <SlidingPane
      className="slider-history"
      overlayClassName="slider-history-overlay"
      isOpen={slidingPaneHistoryState}
      title={
        <HStack spacing="3">
          <Icon
            // className="button-animation"
            minHeight="22px"
            minWidth="22px"
            as={FaClockRotateLeft}
          />
          <Text as="h2" fontWeight="semibold">
            Bridge History
          </Text>
        </HStack>
      }
      subtitle={
        <Text fontSize="14px" fontWeight="medium">
          {account.address}
        </Text>
      }
      width={sliderWidth}
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        setSlidingPaneHistoryState(false);
      }}
      closeIcon={
        <Icon
          className="button-animation"
          minHeight="30px"
          minWidth="30px"
          as={FaXmark}
        />
      }
    >
      {accountHistory && accountHistory.length <= 0 && (
        <Text as="h5" py="10" px={["10"]}>
          No bridging history for this address
        </Text>
      )}
      {accountHistory &&
        accountHistory.length > 0 &&
        accountHistory
          .filter(
            (z) =>
              z.get("name") == "BridgeTokens" ||
              (z.get("name") == "ClaimTokens" && z.get("code") == 2)
          )
          .map((x, i) => (
            <Flex
              key={x.id + i}
              py="5"
              px={["5", "7", "10"]}
              backgroundColor={
                x.get("name") == "ClaimTokens" ? "white" : "blackAlpha.50"
              }
              borderBottom="solid 1px #eeeeee"
            >
              <HStack
                as="a"
                href={`${
                  chainsWithUrl?.find(
                    (y) =>
                      y.id ==
                      x.get(
                        x.get("name") == "ClaimTokens" ? "toChain" : "fromChain"
                      )
                  )?.blockExplorers?.default.url
                }/tx/${x.get("transactionHash")}`}
                target="_blank"
                className="button2-animation"
                cursor="pointer"
                spacing={["5", "7", "10"]}
                flex="1"
              >
                <Icon
                  // className="button-animation"
                  minHeight={x.get("name") == "ClaimTokens" ? "26px" : "24px"}
                  minWidth={x.get("name") == "ClaimTokens" ? "26px" : "24px"}
                  as={
                    x.get("name") == "ClaimTokens"
                      ? FaHandHoldingHeart
                      : FaBridgeWater
                  }
                />

                <VStack alignItems="flex-start">
                  <Text fontSize="sm">
                    {new Date(x.get("blockTimestamp") * 1000).toString()}
                  </Text>
                  <HStack>
                    <Text as="h5">
                      {x.get("name") == "ClaimTokens" ? `Claimed` : `Bridged`}
                    </Text>
                    <Text as="h5" fontWeight="bold">
                      {/* {` ${web3.utils.fromWei(x.get("amount"))} YOU `} */}
                      {` ${formatEther(x.get("amount"))} YOU `}
                    </Text>
                    <Text as="h5">tokens</Text>
                  </HStack>
                  <Text>
                    Sent from{" "}
                    {
                      chainsWithUrl?.find((y) => y.id == x.get("fromChain"))
                        .name
                    }{" "}
                    to{" "}
                    {chainsWithUrl?.find((y) => y.id == x.get("toChain")).name}
                  </Text>
                  <HStack alignItems="flex-end">
                    <Text fontSize="sm">Tx hash: </Text>

                    <Text fontSize="sm" fontWeight="semibold" as="u">
                      {getEllipsisTxt(x.get("transactionHash"), 13)}
                    </Text>
                  </HStack>
                </VStack>
                <Spacer display={["none", "flex"]} />
                {!x.get("confirmed") ? (
                  <Box
                    h={["60px"]}
                    w={["60px"]}
                    alignSelf="center"
                    justifySelf="flex-end"
                  >
                    <Lottie animationData={LoadingAnimation} loop={true} />
                  </Box>
                ) : (
                  <Box w={["24px", "60px"]} alignSelf="center">
                    <Icon
                      // className="button-animation"
                      minHeight="24px"
                      minWidth="24px"
                      as={FaCircleCheck}
                    />
                  </Box>
                )}
              </HStack>
            </Flex>
          ))}
    </SlidingPane>
  );
}
