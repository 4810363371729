import { Text, useToast } from "@chakra-ui/react";
import { useFormikContext } from "formik";

export default function AppFormErrorMessage({
  name,
  title = "Bridge Error",
  status = "error",
}) {
  const { errors, values } = useFormikContext();
  const toast = useToast();
  //   console.log("errors", errors);
  //   console.log("values", values);

  if (!toast.isActive(name) && Boolean(errors[name])) {
    toast({
      id: name,
      title,
      description: errors[name],
      status,
      duration: 9000,
      isClosable: true,
    });
  }

  return <></>;
}
