import "./polyfills";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "react-sliding-pane/dist/react-sliding-pane.css";

import Parse from "parse";
import "@rainbow-me/rainbowkit/styles.css";
import merge from "lodash.merge";
import {
  // getDefaultWallets,
  RainbowKitProvider,
  lightTheme,
  Theme,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import {
  ledgerWallet,
  braveWallet,
  injectedWallet,
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  trustWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { WagmiConfig, createConfig, configureChains } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import {
  mainnet,
  bsc,
  arbitrum,
  goerli,
  // localhost,
  bscTestnet,
  arbitrumGoerli,
} from "@wagmi/core/chains";
// import { InjectedConnector } from "@wagmi/core/connectors/injected";
// import { CoinbaseWalletConnector } from "@wagmi/core/connectors/coinbaseWallet";
// import { MetaMaskConnector } from "@wagmi/core/connectors/metaMask";
// import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { ChakraProvider } from "@chakra-ui/react";

import App from "./App";
import theme from "./js/theme";
// import { provideRainbowKitChains } from "./utils/provideRainbowKitChains";

const myTheme = merge(lightTheme(), {
  colors: {
    accentColor: "#ff5e0e",
    connectionIndicator: "#00c999",
  },
  fonts: {
    body: '"Quicksand", "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
} as Theme);

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    ...(process.env.REACT_APP_IS_MAINNET === "true" ? [bsc, mainnet] : []),
    // ...(process.env.REACT_APP_ENABLE_ARBITRUM === "true" ? [arbitrum] : []),
    ...(process.env.REACT_APP_ENABLE_TESTNETS === "true"
      ? [bscTestnet, goerli]
      : []),
    // ...(process.env.REACT_APP_ENABLE_ARBITRUM === "true" &&
    // process.env.REACT_APP_ENABLE_TESTNETS === "true"
    //   ? [arbitrumGoerli]
    //   : []),
  ],
  [
    // jsonRpcProvider({
    //   rpc: (chain) => ({
    //     http: `https://${chain.id}.example.com`,
    //   }),
    // }),
    publicProvider(),
  ]
);

// const chains = provideRainbowKitChains(chainsNoUrl);
// console.log(chains);

const parseProjectId = String(process.env.REACT_APP_PROJECT_ID);
const projectId = String(process.env.REACT_APP_WC_PROJECT_ID);
const appName = String(process.env.REACT_APP_NAME);
const serverUrl = String(process.env.REACT_APP_SERVER_URL);

console.log("projectId, appName, serverUrl", projectId, appName, serverUrl);

// const { connectors } = getDefaultWallets({
//   appName,
//   projectId,
//   chains,
// });

const connectors = connectorsForWallets([
  {
    groupName: appName,
    wallets: [
      injectedWallet({ chains }),
      metaMaskWallet({ projectId, chains }),
      coinbaseWallet({ chains, appName }),
      trustWallet({ projectId, chains }),
      rainbowWallet({ projectId, chains }),
      walletConnectWallet({
        projectId,
        chains,
      }),
      braveWallet({ chains }),
      ledgerWallet({ projectId, chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

Parse.initialize(parseProjectId); // jsKey
Parse.serverURL = serverUrl;
// Parse.enableLocalDatastore();
// Parse.setAsyncStorage(AsyncStorage);
// Parse.User.enableUnsafeCurrentUser();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        chains={chains}
        theme={myTheme}
        appInfo={{
          appName,
          learnMoreUrl: process.env.REACT_APP_WEBSITE_URL,
        }}
        modalSize="compact"
        showRecentTransactions={true}
      >
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
