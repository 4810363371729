import { useFormikContext } from "formik";
import {
  Box,
  Button,
  Flex,
  Image,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";

export default function AppFormSelectModal({
  name,
  chainsWithUrl,
  fromChain,
  toChain,
  onChangeField,
  onClose,
}) {
  const { setFieldTouched, setFieldValue, errors, touched, values } =
    useFormikContext();

  const handleChangeField = (value) => {
    setFieldValue(name, value);
    setFieldTouched(name);
    if (onChangeField) onChangeField(value);
    onClose();
  };

  return (
    <Stack>
      {chainsWithUrl
        .filter((x) => x.id != fromChain)
        .map((x) => (
          <Button
            key={x.id}
            onClick={
              x.id == toChain ? () => onClose() : () => handleChangeField(x.id)
            }
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            borderRadius="12px"
            variant={x.id == toChain ? "solid" : "ghost"}
            className={x.id == toChain ? "button-shadow2" : ""}
            // _active={{
            //   background: x.id == toChain ? "primary" : undefined,
            // }}
            _hover={{
              background: x.id == toChain ? "primary" : "blackAlpha.200",
            }}
            p="7px"
            h="42px"
            bg={x.id == toChain ? "primary" : undefined}
          >
            <Image
              //   borderRadius="full"
              boxSize="30px"
              src={x.iconUrl}
              alt={x.name}
              mr="8px"
            />
            <Box w="100%">
              <Text
                textAlign="left"
                fontWeight="bold"
                color={x.id == toChain ? "white" : undefined}
              >
                {x.name}
              </Text>
            </Box>
            {x.id == toChain && (
              <>
                <Text fontSize="14px" color="white" fontWeight="medium">
                  Selected
                </Text>
                <Flex
                  borderRadius="20px"
                  height="8px"
                  width="8px"
                  mx="8px"
                  bg="secondary"
                ></Flex>
              </>
            )}
          </Button>
        ))}
    </Stack>
  );
}
