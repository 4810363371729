import { isNotNullish } from "./isNotNullish";
import type { RainbowKitChain } from "./RainbowKitChainContext";
import arbitrumIconSvg from "./chainIcons/arbitrum.svg";
import bscIconSvg from "./chainIcons/bsc.svg";
import ethereumIconSvg from "./chainIcons/ethereum.svg";

// Sourced from https://github.com/tmm/wagmi/blob/main/packages/core/src/constants/chains.ts
// This is just so we can clearly see which of wagmi's first-class chains we provide metadata for
type ChainName =
  | "arbitrum"
  | "arbitrumGoerli"
  | "avalanche"
  | "avalancheFuji"
  | "cronos"
  | "cronosTestnet"
  | "base"
  | "baseGoerli"
  | "bsc"
  | "bscTestnet"
  | "goerli"
  | "hardhat"
  | "kovan"
  | "localhost"
  | "mainnet"
  | "optimism"
  | "optimismKovan"
  | "optimismGoerli"
  | "polygon"
  | "polygonMumbai"
  | "rinkeby"
  | "ropsten"
  | "sepolia"
  | "zora"
  | "zoraTestnet";

type IconMetadata = {
  iconUrl: any;
  iconBackground: string;
};

type ChainMetadata = {
  chainId: number;
  name?: string;
  youTokenAddress?: string;
} & IconMetadata;

const arbitrumIcon: IconMetadata = {
  iconBackground: "#96bedc",
  iconUrl: arbitrumIconSvg,
};

const avalancheIcon: IconMetadata = {
  iconBackground: "#e84141",
  iconUrl: "/src/utils/chainIcons/avalanche.svg",
};

const baseIcon: IconMetadata = {
  iconBackground: "#0052ff",
  iconUrl: "/src/utils/chainIcons/base.svg",
};

const bscIcon: IconMetadata = {
  iconBackground: "#ebac0e",
  iconUrl: bscIconSvg,
};

const cronosIcon: IconMetadata = {
  iconBackground: "#002D74",
  iconUrl: "/src/utils/chainIcons/cronos.svg",
};

const ethereumIcon: IconMetadata = {
  iconBackground: "#484c50",
  iconUrl: ethereumIconSvg,
};

const hardhatIcon: IconMetadata = {
  iconBackground: "#f9f7ec",
  iconUrl: "/src/utils/chainIcons/hardhat.svg",
};

const optimismIcon: IconMetadata = {
  iconBackground: "#ff5a57",
  iconUrl: "/src/utils/chainIcons/optimism.svg",
};

const polygonIcon: IconMetadata = {
  iconBackground: "#9f71ec",
  iconUrl: "/src/utils/chainIcons/polygon.svg",
};

const zoraIcon: IconMetadata = {
  iconBackground: "#000000",
  iconUrl: "/src/utils/chainIcons/zora.svg",
};

const chainMetadataByName: Record<ChainName, ChainMetadata | null> = {
  arbitrum: {
    chainId: 42_161,
    name: "Arbitrum",
    ...arbitrumIcon,
    youTokenAddress: "0x2FAc624899a844E0628BfDCC70eFCd25F6E90B95",
  },
  arbitrumGoerli: { chainId: 421_613, name: "Arbitrum Testnet", ...arbitrumIcon, youTokenAddress: "0x99a9c34b1447F43A80BEd5Bc29b4608C31226d0c" },
  avalanche: { chainId: 43_114, ...avalancheIcon },
  avalancheFuji: { chainId: 43_113, ...avalancheIcon },
  base: { chainId: 8453, ...baseIcon },
  baseGoerli: { chainId: 84531, ...baseIcon },
  bsc: {
    chainId: 56,
    name: "BSC",
    ...bscIcon,
    youTokenAddress: "0xB583961E033Dfe0FfF161952f7BA21c411b6103d",
  },
  bscTestnet: {
    chainId: 97,
    name: "BSC Testnet",
    ...bscIcon,
    youTokenAddress: "0x99a9c34b1447F43A80BEd5Bc29b4608C31226d0c",
  },
  cronos: { chainId: 25, ...cronosIcon },
  cronosTestnet: { chainId: 338, ...cronosIcon },
  goerli: {
    chainId: 5,
    name: "Goerli Testnet",
    ...ethereumIcon,
    youTokenAddress: "0x85eA0F5E224baf6Ff9Be49da843951C6C6a1fDb2",
  },
  hardhat: { chainId: 31_337, ...hardhatIcon },
  kovan: { chainId: 42, ...ethereumIcon },
  localhost: { chainId: 1_337, ...ethereumIcon },
  mainnet: {
    chainId: 1,
    name: "Ethereum",
    ...ethereumIcon,
    youTokenAddress: "0xb92ba0A6a843379499770dE82Aa936d6bA0fd8CA",
  },
  optimism: { chainId: 10, name: "Optimism", ...optimismIcon },
  optimismGoerli: { chainId: 420, ...optimismIcon },
  optimismKovan: { chainId: 69, ...optimismIcon },
  polygon: { chainId: 137, ...polygonIcon },
  polygonMumbai: { chainId: 80_001, ...polygonIcon },
  rinkeby: { chainId: 4, ...ethereumIcon },
  ropsten: { chainId: 3, ...ethereumIcon },
  sepolia: { chainId: 11_155_111, ...ethereumIcon },
  zora: { chainId: 7777777, ...zoraIcon },
  zoraTestnet: { chainId: 999, ...zoraIcon },
};

const chainMetadataById = Object.fromEntries(
  Object.values(chainMetadataByName)
    .filter(isNotNullish)
    .map(({ chainId, ...metadata }) => [chainId, metadata])
);

/** @description Decorates an array of wagmi `Chain` objects with RainbowKitChain property overrides */
export const provideRainbowKitChains = <Chain extends RainbowKitChain>(
  chains: Chain[]
): Chain[] =>
  chains.map((chain) => ({
    ...chain,
    ...(chainMetadataById[chain.id] ?? {}),
  }));
