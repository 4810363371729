import PageLayout from "./components/PageLayout";
import AppAuth from "./components/AppAuth";

const App = () => {
  return (
    <PageLayout>
      <AppAuth />
    </PageLayout>
  );
};

export default App;
