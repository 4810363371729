import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  ListItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  OrderedList,
  Spacer,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Parse from "parse";
import { FaHistory } from "react-icons/fa";
import { FaScrewdriverWrench } from "react-icons/fa6";
import { IoArrowDownOutline, IoArrowForwardOutline } from "react-icons/io5";
import { useNetwork, useBalance } from "wagmi";
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit";
import * as Yup from "yup";
import Web3 from "web3";
import { formatEther, parseEther } from "viem";

import { provideRainbowKitChains } from "../utils/provideRainbowKitChains";
import SlidingPaneClaim from "./SlidingPaneClaim";
import SlidingPaneHistory from "./SlidingPaneHistory";
import AppModal from "./AppModal";
import AppForm from "./AppForm";
import AppFormNumberInput from "./AppFormNumberInput";
import AppFormSubmitButton from "./AppFormSubmitButton";
import AppLoadingAnimation from "./AppLoadingAnimation";
import AppFormErrorMessage from "./AppFormErrorMessage";
import AppFormSelectModal from "./AppFormSelectModal";
import useSolidity from "../hooks/useSolidity";
import { useDebounce } from "usehooks-ts";
import { cwError } from "../js/helpers";

const web3 = new Web3(Web3.givenProvider);
const BN = web3.utils.BN;

// The minimum ABI to get ERC20 Token balance
let minABI = [
  // balanceOf
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  // decimals
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    type: "function",
  },
];

export default function BridgeConnected({
  account,
  chain,
  openAccountModal,
  openChainModal,
}) {
  const [slidingPaneHistoryState, setSlidingPaneHistoryState] = useState(false);
  const [slidingPaneClaimState, setSlidingPaneClaimState] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serverIsAvailable, setServerIsAvailable] = useState(true);
  const [configBridge, setConfigBridge] = useState(undefined);
  const [youBalance, setYouBalance] = useState(undefined);
  const [youAmount, setYouAmount] = useState(undefined);
  const [fromChain, setFromChain] = useState(chain.id);
  const { chains } = useNetwork();
  const [toChain, setToChain] = useState(
    chains?.find((x) => x.id != chain.id).id
  );
  const chainsWithUrl = provideRainbowKitChains(chains);
  const [toChainFull, setToChainFull] = useState(
    chainsWithUrl?.find((x) => x.id != chain.id)
  );
  // const chainsWithUrl = chains; // this works if the chains are formatted in index.ts

  const IoArrowIcon = useBreakpointValue(
    {
      base: IoArrowDownOutline,
      sm: IoArrowForwardOutline,
    },
    {
      // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
      // (Defaults to 'base')
      fallback: "sm",
    }
  );

  const bridgingCooldown = useMemo(
    () => configBridge?.get("bridgingCooldown") ?? "30",
    [configBridge]
  );

  const debouncedConfigBridge = useDebounce(configBridge, 2000);
  const debouncedFromChain = useDebounce(fromChain, 2000);
  const debouncedAccount = useDebounce(account, 2000);

  const {
    bridgeTokens,
    claimTokens,
    getTokenBalance,
    getTokenAllowance,
    approveTokenAllowance,
    getBridgeInfo,
    configObject,
    tokenAllowance,
    tokenBalance,
    bridgeAbi,
    bridgeIsPaused,
    bridgeInfoFrom,
    bridgeInfoTo,
  } = useSolidity(String(fromChain), String(toChain), setIsSubmitting);

  const validationSchema = useCallback(
    () =>
      Yup.object().shape({
        amount: Yup.number()
          .min(0)
          .max(youBalance)
          .required("Please enter an amount.")
          .label("Bridge amount"),
        fromChain: Yup.number().required(
          "Please Select a chain to bridge from."
        ),
        toChain: Yup.number()
          .required("Please Select a chain to bridge to.")
          .notOneOf(
            [Yup.ref("fromChain"), null],
            "Cannot bridge to the same chain, please select a different chain to bridge to."
          ),

        // title: Yup.string().required().min(1).label("Title"),
        // category: Yup.string().required().min(1).label("Category"),
        // subCategory: Yup.string().required().min(1).label("Sub-Category"),
        // rate: Yup.number().required().min(1).max(10000).label("Rate"),
        // description: Yup.string().label("Description"),
        // images: Yup.array().min(1, "Please select at least one image."),
      }),
    [youBalance]
  );

  const toast = useToast();

  const {
    isOpen: isOpenHowTo,
    onOpen: onOpenHowTo,
    onClose: onCloseHowTo,
  } = useDisclosure();
  const {
    isOpen: isOpenToChain,
    onOpen: onOpenToChain,
    onClose: onCloseToChain,
  } = useDisclosure();

  // const { data, isError, isLoading } = useBalance({
  //   address: chains.find((x) => x.id == chain.id).youTokenAddress,
  // });

  // console.log("youAmount", youAmount);
  // console.log("bridgeYouAllowance", bridgeYouAllowance);

  // console.log(
  //   "chains.find((x) => x.id == chain.id).youTokenAddress",
  //   chainsWithUrl.find((x) => x.id == chain.id).youTokenAddress
  // );

  // console.log("chain", chain);
  // console.log("chains", chains);
  // console.log("chainsWithUrl", chainsWithUrl);
  // console.log("configBridge", configBridge?.get("evmChains"));

  const handleSubmit = async (
    { amount, fromChain, toChain },
    { resetForm, setSubmitting }
  ) => {
    try {
      // addRecentTransaction({
      //   hash: "0xb0c0ce2a071b6f3eb1a28184e5e2b4bc1ec6623c975e73bf0e6f433c722b4e88",
      //   description: "wtihdraw",
      // });
      console.log("handleSubmit: ", { amount, fromChain, toChain });

      setIsSubmitting(true);

      // web3
      // if (web3.utils.toWei(String(youAmount ?? 0)) > tokenAllowance) {
      //   console.log("submit approve");
      //   await approveTokenAllowance(web3.utils.toWei(String(amount)));
      // } else {
      //   console.log("submit bridge");
      //   await bridgeTokens(web3.utils.toWei(String(amount)));
      // }

      // viem
      if (parseEther(youAmount ?? "0") > tokenAllowance) {
        console.log("submit approve");
        await approveTokenAllowance(parseEther(amount));
      } else {
        console.log("submit bridge");
        await bridgeTokens(parseEther(amount));
        resetForm();
      }
    } catch (error) {
      cwError(error, "handleSubmit");
      setIsSubmitting(false);
    } finally {
      setSubmitting(false);
      setTimeout(() => setIsSubmitting(false), 60000);
    }
  };

  const getTokenBalanceAllowanceBridge = async () => {
    try {
      // const query = new Parse.Query("Config");
      // const result = await query.first();
      // setConfigBridge(result);
      // setWelcomeMessage(result?.get("message"));

      // let balance = await web3.eth.getBalance(account.address);
      // balance = web3.utils.fromWei(balance);
      // console.log("balance", balance);

      // let contract = new web3.eth.Contract(
      //   minABI,
      //   chainsWithUrl.find((x) => x.id == chain.id).youTokenAddress
      // );

      // let balanceYou = await contract.methods.balanceOf(account.address).call();
      // balanceYou = web3.utils.fromWei(balanceYou);
      // console.log("balanceYou", balanceYou);
      // console.log("before getTokenAllowance");
      const tokenAllowanceAccount = await getTokenAllowance();
      // console.log("getTokenAllowance: " + tokenAllowanceAccount);
      // console.log("before getTokenBalance");
      const tokenBalanceAccount = await getTokenBalance();
      // console.log("getTokenBalance: " + tokenBalanceAccount);
      // setYouBalance(web3.utils.fromWei(String(tokenBalanceAccount)));
      // setYouAmount(web3.utils.fromWei(String(tokenBalanceAccount)));
      setYouBalance(formatEther(tokenBalanceAccount));
      setYouAmount(formatEther(tokenBalanceAccount));

      setServerIsAvailable(true);
    } catch (error) {
      setServerIsAvailable(false);
      setYouBalance(0);
      cwError(error, "handleSubmit");
      if (!toast.isActive(error.name)) {
        toast({
          id: error.name,
          title: "Server Down.",
          description:
            "We are unable to connect to the bridge relay server at this time. Please try again soon.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    setConfigBridge(configObject);
  }, [configObject]);

  useEffect(() => {
    setFromChain(chain.id);
    if (chains) setToChain(chains.find((x) => x.id != chain.id).id);
  }, [account, chain]);

  useEffect(() => {
    if (configBridge) {
      getTokenBalanceAllowanceBridge();
    } else {
      setYouBalance(undefined);
    }
  }, [debouncedFromChain, debouncedConfigBridge, debouncedAccount]);

  useEffect(() => {
    if (chainsWithUrl)
      setToChainFull(chainsWithUrl.find((x) => x.id == toChain));
  }, [toChain]);

  if (!serverIsAvailable) {
    return (
      <Flex alignItems="center" h="100%" maxW="400px">
        <Stack spacing={5}>
          <HStack>
            <Icon
              //   color="gray.rb"
              boxSize={6}
              as={FaScrewdriverWrench}
              alignSelf="center"
            />
            <Text fontSize="2xl" fontWeight="semibold" ml="1">
              Server Maintenance
            </Text>
          </HStack>
          <Text fontSize="md">
            We are unable to reach the bridge relay server at this point in
            time. Please try again soon, or press the Retry button below to
            attempt to reconnect to the server.
          </Text>
          <Button
            mt="2"
            alignSelf="center"
            colorScheme="yellow"
            onClick={getTokenBalanceAllowanceBridge}
            borderRadius="12px"
            className="button-animation"
          >
            Retry
          </Button>
        </Stack>
      </Flex>
    );
  }

  if (youBalance == undefined) {
    return <AppLoadingAnimation />;
  }

  return (
    <>
      <IconButton
        className="button-animation"
        isRound
        icon={<FaHistory />}
        color="gray.rb"
        onClick={() => setSlidingPaneHistoryState(true)}
        //   size="sm"
        cursor="pointer"
        fontSize="16px"
        pos="absolute"
        top="16px"
        right="16px"
        zIndex="1"
      />

      <AppForm
        initialValues={{
          amount: youBalance,
          fromChain,
          toChain,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Stack spacing={3}>
          <HStack>
            <Button
              className="text-shadow"
              variant="link"
              display="flex"
              alignSelf="flex-start"
              onClick={openAccountModal}
              colorScheme="black"
              fontSize="xl"
              fontWeight="semibold"
              textDecoration="underline"
            >
              Bridge
            </Button>
            <Text>|</Text>
            <Button
              className="text-shadow"
              variant="link"
              display="flex"
              alignSelf="flex-start"
              onClick={() => setSlidingPaneClaimState(true)}
              colorScheme="black"
              fontSize="xl"
              fontWeight="semibold"
            >
              Claim
            </Button>
            <Text>|</Text>
            <Button
              className="text-shadow"
              variant="link"
              display="flex"
              alignSelf="flex-start"
              onClick={onOpenHowTo}
              colorScheme="black"
              fontSize="xl"
              fontWeight="semibold"
            >
              How to
            </Button>
          </HStack>

          {/* <Text>{welcomeMessage}</Text> */}

          <Stack spacing={3} mt="3">
            <AppFormNumberInput
              name="amount"
              balance={youBalance}
              onChangeField={setYouAmount}
            />

            <Stack spacing={0}>
              <Divider />

              <Flex
                flexDirection={["column", "row"]}
                alignItems="center"
                justifyContent="space-between"
                // maxW="300px"
              >
                <HStack>
                  {/* <Text>From </Text> */}
                  <Button
                    onClick={openChainModal}
                    //   display="flex"
                    //   alignSelf="flex-start"
                    variant="ghost"
                    colorScheme="orange"
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          // width: 12,
                          // height: 12,
                          borderRadius: 999,
                          // overflow: "hidden",
                          marginRight: 8,
                        }}
                      >
                        {chain.iconUrl && (
                          <Image
                            //   borderRadius="full"
                            boxSize="30px"
                            h="30px"
                            w="30px"
                            minW="30px"
                            src={chain.iconUrl}
                            alt={chain.name ?? "Chain icon"}
                          />
                        )}
                      </div>
                    )}
                    {chain.name}
                  </Button>
                </HStack>

                <Icon
                  //   color="gray.rb"
                  boxSize={6}
                  as={IoArrowIcon}
                  alignSelf="center"
                  my={["3", "0"]}
                />

                <HStack>
                  {/* <Text>To </Text> */}
                  <Button
                    onClick={onOpenToChain}
                    //   display="flex"
                    //   alignSelf="flex-start"
                    variant="ghost"
                    colorScheme="green"
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          // width: 12,
                          // height: 12,
                          borderRadius: 999,
                          // overflow: "hidden",
                          marginRight: 8,
                        }}
                      >
                        {toChainFull && (
                          <Image
                            //   borderRadius="full"
                            boxSize="30px"
                            h="30px"
                            w="30px"
                            minW="30px"
                            src={toChainFull?.iconUrl}
                            alt={toChainFull?.name ?? "Chain icon"}
                          />
                        )}
                      </div>
                    )}
                    {toChainFull?.name}
                  </Button>
                </HStack>
              </Flex>
              <AppFormErrorMessage name="toChain" />
              <Divider />
            </Stack>
          </Stack>

          <AppModal
            className="to-chain-modal"
            isOpen={isOpenToChain}
            onClose={onCloseToChain}
            modalHeader="Bridge To"
            modalBody={
              <AppFormSelectModal
                name="toChain"
                chainsWithUrl={chainsWithUrl}
                fromChain={fromChain}
                toChain={toChain}
                onChangeField={setToChain}
                onClose={onCloseToChain}
              />
            }
          />

          {parseEther(youAmount ?? "0") > tokenAllowance && (
            <HStack alignItems="baseline" justifyContent="flex-end" pr="2">
              <Text fontSize="sm" fontWeight="normal" flexDirection="row">
                Current allowance:
              </Text>
              <Text fontSize="sm" fontWeight="semibold">
                {" "}
                {formatEther(tokenAllowance)} YOU
              </Text>
            </HStack>
          )}

          <AppFormSubmitButton
            colorScheme="white"
            background={
              // web3.utils.toWei(youAmount ? String(youAmount) : "0") >
              parseEther(youAmount ?? "0") > tokenAllowance
                ? "primary"
                : "secondary"
            }
            size="lg"
            borderRadius="12px"
            buttonText={
              // web3.utils.toWei(youAmount ? String(youAmount) : "0") >
              parseEther(youAmount ?? "0") > tokenAllowance ? (
                <Text>Approve</Text>
              ) : (
                <Text>Bridge</Text>
              )
            }
            style={{ minHeight: "55px" }}
            // isDisabled={Boolean(isSubmitting || !youAmount || youAmount <= 0)}
            isLoading={isSubmitting}
          />
        </Stack>
      </AppForm>

      <AppModal
        size="lg"
        isOpen={isOpenHowTo}
        onClose={onCloseHowTo}
        pt="20px"
        pb="8px"
        modalHeader="How to bridge YOU tokens"
        modalBody={
          <Stack>
            <OrderedList pl="20px">
              <ListItem>Enter the amount of YOU tokens to bridge.</ListItem>
              <ListItem>
                Select the chain you are sending YOU tokens from.
              </ListItem>
              <ListItem>
                Select the chain you are sending YOU tokens to.
              </ListItem>
              <ListItem>
                Click the Bridge button then Confirm bridging on the pop up
                window. (Note: If this is the first time bridging from the
                selected blockchain, the bridge may request approval to manage
                your YOU tokens)
              </ListItem>
              <ListItem>
                Confirm the bridging transaction using your wallet of choice.
              </ListItem>
              <ListItem>
                Wait approximately {bridgingCooldown} minutes.
              </ListItem>
              <ListItem>Click on 'Claim'.</ListItem>
              <ListItem>
                If you have a bridge that is ready to be claimed, click the
                'Claim' button next to the amount you are bridging.
              </ListItem>
              <ListItem>
                Confirm the claiming transaction using your wallet of choice.
              </ListItem>
              <ListItem>
                The tokens should appear in your wallet within 5 minutes. Make
                sure you have selected the correct chain in your wallet app.
              </ListItem>
              <ListItem>
                <HStack>
                  <Text>View your bridging history by pressing the </Text>{" "}
                  <FaHistory /> <Text>button.</Text>
                </HStack>
              </ListItem>
            </OrderedList>
            <Divider />
            <Text>
              If you have any questions please dont hesitate to contact us. Join
              our discord at{" "}
              <Link
                href="https://discord.youwho.io"
                isExternal
                color="secondary"
                fontWeight="bold"
              >
                discord.youwho.io
              </Link>{" "}
              or email us at{" "}
              <Link
                href="mailto:youwho@youwho.io"
                color="primary"
                fontWeight="bold"
              >
                youwho@youwho.io
              </Link>
            </Text>
          </Stack>
        }
        modalFooter={
          <>
            <Button
              className="button-youwho2"
              colorScheme="orange"
              bg="secondary"
              onClick={onCloseHowTo}
            >
              Close
            </Button>
          </>
        }
      />

      <SlidingPaneClaim
        slidingPaneClaimState={slidingPaneClaimState}
        setSlidingPaneClaimState={setSlidingPaneClaimState}
        account={account}
        debouncedAccount={debouncedAccount}
        chainsWithUrl={chainsWithUrl}
        claimTokens={claimTokens}
        isSubmitting={isSubmitting}
        bridgingCooldown={bridgingCooldown}
      />
      <SlidingPaneHistory
        slidingPaneHistoryState={slidingPaneHistoryState}
        setSlidingPaneHistoryState={setSlidingPaneHistoryState}
        account={account}
        debouncedAccount={debouncedAccount}
        chainsWithUrl={chainsWithUrl}
      />
    </>
  );
}
