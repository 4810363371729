import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { FaXmark } from "react-icons/fa6";

export default function AppModal({
  isOpen,
  onClose,
  modalHeader,
  modalBody,
  modalFooter,
  size = "sm",
  ...otherProps
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
      scrollBehavior="outside"
      size={size}
    >
      <ModalOverlay />
      <ModalContent borderRadius={["0px", "24px"]} {...otherProps}>
        <ModalHeader>
          <Text as="h1" fontSize="18px" fontWeight="bold" mt="4px">
            {modalHeader}
          </Text>
        </ModalHeader>
        {/* <ModalCloseButton
          className="button-animation"
          borderRadius="100px"
          //   color="gray.rb"
          fontSize="10px"
          pos="absolute"
          top="16px"
          right="16px"
          //   zIndex="1"
          m="0"
          variant="solid"
        /> */}

        <IconButton
          className="button-animation"
          isRound
          icon={<FaXmark />}
          color="gray.rb"
          onClick={onClose}
          size="sm"
          cursor="pointer"
          fontSize="16px"
          pos="absolute"
          top="16px"
          right="16px"
          zIndex="1"
        />

        <ModalBody>{modalBody}</ModalBody>

        <ModalFooter>{modalFooter}</ModalFooter>
      </ModalContent>
    </Modal>
  );
}
