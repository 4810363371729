import { Box, Flex, Text } from "@chakra-ui/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ReactSVG } from "react-svg";

export default function PageLayout({ children }) {
  return (
    <Box
      // className="p-sm-3"
      minH="600px"
      minW="300px"
      h="100vh"
      w="100vw"
      background="radial-gradient(circle at 20vw 100vh, rgba(255, 94, 14, 0.1) 0%, rgba(160, 160, 160, 0.3) 30%, rgba(0, 201, 153, 0.3) 100%)"
    >
      <Flex
        flexDirection={["column", , "row"]}
        flex="1"
        p="4"
        justifyContent={["flex-start", , "space-between"]}
      >
        <Flex flexDirection={["column", , , "row"]}>
          <Flex>
            <a
              href="https://youwho.io"
              target="_blank"
              className="button-animation"
            >
              <ReactSVG
                // wrapper="svg"

                className="youwho-logo"
                style={{ width: "260px" }}
                src="./assets/svg/youwho-logos_text-logo-new-trans.svg"
              />
            </a>
          </Flex>

          <Flex fontSize="32px" verticalAlign="top">
            <Box display={["none", , , "block"]} mx="3">
              {"|"}
            </Box>
            <Text className="bridge-heading">
              <Text as="b">YOU</Text> Token Bridge
            </Text>
          </Flex>
        </Flex>

        <Flex
          display={["none", , "flex"]}
          justifyContent={["flex-start", , "flex-end"]}
          h="40px"
        >
          <ConnectButton />
        </Flex>
      </Flex>

      {children}

      <Box
        display={["block", , "none"]}
        pos="absolute"
        right="0"
        bottom="0"
        p="4"
      >
        <ConnectButton />
      </Box>
    </Box>
  );
}
