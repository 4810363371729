import Lottie from "lottie-react";
import LoadingAnimation from "../assets/loadingDotsLottieColor.json";
import { Flex, HStack, Stack, Text } from "@chakra-ui/react";

export default function AppLoadingAnimation() {
  return (
    <Flex alignItems="center" h="100%" maxW="400px">
      <Stack spacing={0}>
        <HStack>
          <Text fontSize="3xl">Getting </Text>
          <Text fontSize="3xl" fontWeight="bold">
            YOU
          </Text>
          <Text fontSize="3xl"> Balance ...</Text>
        </HStack>
        <Flex h="100px" w="100px" alignSelf="center">
          <Lottie animationData={LoadingAnimation} loop={true} />
        </Flex>
      </Stack>
    </Flex>
  );
}
