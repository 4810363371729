import { cwError } from "./helpers";

export const getEllipsisTxt = (str, n = 6) => {
  if (str && str.length >= n + 3) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  } else {
    return str;
  }
};

export const getErrorDetails = (str) => {
  // return str;
  try {
    let details;
    if (str?.includes("Details: ")) {
      const details1 = str?.split("Details: ")[1];
      details = details1?.split("Version: ")[0];
    } else if (str?.includes("following reason:")) {
      console.log("reason");
      const details1 = str?.split("following reason:")[1];
      details = details1?.split("Contract Call:")[0];
    } else {
      details = str;
    }
    return details;
  } catch (error) {
    cwError(error, "getErrorDetails");
    return str;
  }
};
