import { useState, useEffect } from "react";

export default function useCountdownTimer({ targetDate }) {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );
  const [timesUp, setTimesUp] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return {
    secondsRemaining: Math.floor(countDown / 1000),
    timesUp: countDown <= 0,
    minutesRemaining: Math.round(countDown / 1000 / 60),
    showSeconds:
      Math.round(countDown / 1000) <= 90 && Math.round(countDown / 1000) >= 0,
  };
}
