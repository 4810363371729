import { Button, Flex, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { FaLinkSlash } from "react-icons/fa6";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export default function BridgeNoChain({ openChainModal }) {
  return (
    <Flex alignItems="center" h="100%" maxW="400px">
      <Stack spacing={5}>
        <HStack>
          <Icon
            //   color="gray.rb"
            boxSize={6}
            as={FaLinkSlash}
            alignSelf="center"
          />
          <Text fontSize="2xl" fontWeight="semibold" ml="1">
            Unsupported Network
          </Text>
        </HStack>
        <Text fontSize="md">
          Please press the button below and choose from one of the supported
          networks to continue bridging your YOU tokens.
        </Text>

        <Flex mt="2" alignSelf="center">
          <ConnectButton />
        </Flex>
      </Stack>
    </Flex>
  );
}
